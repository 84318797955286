import { createWebHistory, createRouter } from "vue-router";
/*
import axios from 'axios';
import appConfig from "../../app.config";
import { watch } from "vue";

import store from '@/state/store';
*/
import routes from './routes';
import { authGuard } from '@auth0/auth0-vue';


//import { useAuth0 } from "@auth0/auth0-vue";
//const auth0 = useAuth0();

const createRouterInstance = () => {


  
  routes.map(route => {
    route.beforeEnter = authGuard;
  });

  const router = createRouter({
    history: createWebHistory('/'),
    routes,
    // Use the HTML5 history API (i.e. normal-looking routes)
    // instead of routes with hashes (e.g. example.com/#/about).
    // This may require some server configuration in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    mode: 'history',
    // Simulate native-like scroll behavior when navigating to a new
    // route and using back/forward buttons.
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { top: 0, left: 0 };
      }
    },
  });

  return router;
};

export default createRouterInstance;
