import { useAuth0 } from '@auth0/auth0-vue';
import { getFirebaseBackend } from '../../authUtils.js'
import { watch } from "vue";

export const state = {
    currentUser: sessionStorage.getItem('authUser'),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        console.log("SET_CURRENT_USER", newValue);
        state.currentUser = newValue
        saveState('auth.currentUser', newValue)
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        console.log("loggedIn...", state.currentUser);
        return !!state.currentUser
    }
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        const auth0Plugin = useAuth0();
        if (auth0Plugin) {
            watch( () => [auth0Plugin.isAuthenticated,auth0Plugin.isLoading], () => {
                console.log("The State has changed?",auth0Plugin.isAuthenticated.value  ,
                auth0Plugin.isLoading.value );
            }, { immediate:true } );
        }

        dispatch('validate');
    },

    // Logs in the current user.
    logIn({ commit }, user = {}) {
       // if (getters.loggedIn) return dispatch('validate')
        console.log("logInlogInlogIn");
        commit('SET_CURRENT_USER', user)
        return user;
    },

    // Logs out the current user.
    logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit('SET_CURRENT_USER', null)
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-unused-vars
            getFirebaseBackend().logout().then((response) => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    },

    // register the user
    register({ commit, dispatch, getters }, { username, email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().registerUser(username, email, password).then((response) => {
            const user = response
            commit('SET_CURRENT_USER', user)
            return user
        });
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit, dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().forgetPassword(email).then((response) => {
            const message = response.data
            return message
        });
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        console.log("validate state.currentUser", state.currentUser);
        if (!state.currentUser) return Promise.resolve(null)
      /*  const user = getFirebaseBackend().getAuthenticatedUser();
        console.log("validate", user);
        commit('SET_CURRENT_USER', user)
        return user;*/
        return state.currentUser;
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}

