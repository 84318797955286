import { createApp } from 'vue';
import App from './App.vue';
import createRouterInstance from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";


import BootstrapVueNext from 'bootstrap-vue-next';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';

import { createAuth0 } from '@auth0/auth0-vue';

import '@/assets/scss/config/modern/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'

// Import the plugin here
//import { Auth0Plugin } from './plugins/auth0';

// FakeBackend authentication
// import { configureFakeBackend } from './helpers/fake-backend';
// configureFakeBackend();


// Firebase authentication
import { initFirebaseBackend } from './authUtils';

// const firebaseConfig = {
//     apiKey: process.env.VUE_APP_APIKEY,
//     authDomain: process.env.VUE_APP_AUTHDOMAIN,
//     databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//     projectId: process.env.VUE_APP_PROJECTId,
//     storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//     messagingSenderId: process.env.VUE_APP_MEASUREMENTID
// };

 initFirebaseBackend({});



AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

const rootUrl = location.protocol + '//' + location.hostname + 
(location.port != 80 && location.port != 443 ? (":" + location.port) : "")
   + "/";
const authDomain = "merge2doc.us.auth0.com";
const authClientId="vAt4pBWHwXImiqQXPDC9gRRzelHN0aFi";

const auth0Plugin =
    createAuth0({
      domain: authDomain,
      clientId: authClientId,
      authorizationParams: {
        audience: 'https://docgeneratorapi.com',
        tokenSigningAlg: 'RS256',
        redirect_uri: rootUrl
      }
    });


const app = createApp(App);
    app
    .use(store)
    .use(createRouterInstance(auth0Plugin))
    .use(auth0Plugin)
    .use(VueApexCharts)
    .use(BootstrapVueNext)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(i18n)    
    .use(vClickOutside).mount('#app');